html {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  height: 100%; }

body {
  margin: 0;
  height: 100%;
  background: #182024;
  font-family: Avenir,Helvetica,Arial,sans-serif; }

a {
  color: #CFD8DC; }
