.Transaction {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  font-size: 14px;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: pointer; }
  .Transaction:hover {
    background: #1c252a;
    box-shadow: 0 0 1px 1px #fff, 0 0 2px 1px #0ff; }
  .Transaction-background {
    border-radius: 50%;
    position: absolute; }
  .Transaction-foreground {
    background: #182024;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .Transaction-subText {
    font-size: 12px;
    margin-top: 3px;
    text-align: center;
    color: #e7d6b5; }
    .Transaction-subText > div {
      display: flex;
      align-items: center; }
    .Transaction-subText .Icon {
      margin-bottom: -2px; }
    .Transaction-subText span {
      color: #a1a1aa;
      font-size: 10px; }
  .Transaction-TypeTag {
    margin-top: -7px;
    margin-bottom: 3px;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 3px;
    border: 1px solid #464646;
    color: #e4e4e7;
    background: #182024; }
    .Transaction-TypeTag-transfer {
      color: #5dc8e2; }
    .Transaction-TypeTag-data {
      color: #A2A9F3; }
    .Transaction-TypeTag-newContract {
      color: #e7d6b5; }
    .Transaction-TypeTag-reverted {
      color: #ce8250; }
    .Transaction-TypeTag-clauses {
      color: #e7d6b5; }

@keyframes spin {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(359deg); } }
