.BottomBar {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #91ff00;
  width: 100%;
  background: #000060;
  z-index: 2147483600;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0; }
  .BottomBar:hover {
    background: #020091; }
  .BottomBar svg {
    position: absolute;
    right: -5px;
    top: 2px; }
    .BottomBar svg > circle {
      fill: silver; }
    @media only screen and (max-width: 475px) {
      .BottomBar svg {
        top: -2px;
        right: -7px; } }
  .BottomBar-left {
    margin-right: 20px; }
  .BottomBar-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%; }
    .BottomBar-wrapper > div {
      padding: 0 20px;
      text-align: center; }
      @media only screen and (max-width: 475px) {
        .BottomBar-wrapper > div {
          font-size: 13px; } }
  .BottomBar-header {
    color: #a1a1aa; }
  .BottomBar-number {
    transition: color 200ms; }
    .BottomBar-number-changing {
      color: #e7d6b5; }
  @media only screen and (min-width: 475px) {
    .BottomBar-vtho {
      font-size: 18px; } }
  .BottomBar-button {
    margin-top: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 8px;
    cursor: pointer; }
