.Visualizer {
  box-sizing: border-box;
  background: linear-gradient(to top, #1c252a, #182024);
  color: #CFD8DC;
  position: relative;
  overflow: hidden;
  height: 100%; }
  .Visualizer-blockNumber {
    position: absolute;
    padding: 10px;
    font-size: 14px;
    z-index: 2147483600;
    color: #CFD8DC;
    text-decoration: none; }
  .Visualizer-rightControls {
    position: absolute;
    right: -6px;
    top: -4px;
    padding: 10px;
    z-index: 2147483600;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Visualizer-rightControls-donateIcon {
      height: 20px; }
    .Visualizer-rightControls > * {
      margin-bottom: 15px; }
      .Visualizer-rightControls > *:hover {
        filter: brightness(125%); }

.floating-button {
  position: fixed;
  top: 40px;
  right: 10px;
  font-family: Lato;
  font-weight: 100;
  font-size: 12px;
  color: #fff;
  background-color: #FF000050;
  padding: 6px 18px;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  transition: 0ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 9999999; }

.Visualizer-rightControls {
  position: absolute;
  top: 10px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .Visualizer-rightControls .Visualizer-time {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 2px; }
  .Visualizer-rightControls .Icon {
    cursor: pointer;
    align-self: flex-end; }
