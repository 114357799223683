.BurnTxsAndClausesCharts-avgBurn {
  margin-top: 10px;
  text-align: center; }
  .BurnTxsAndClausesCharts-avgBurn span {
    color: #e7d6b5; }

.BurnTxsAndClausesCharts-sliderRow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; }
  .BurnTxsAndClausesCharts-sliderRow .Checkbox {
    margin-left: 10px; }

.Slider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px; }
  .Slider > label {
    margin-left: 10px; }
